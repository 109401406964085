var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "headerContainer__alignRight" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn--sm btn--black btn--text btn--withIconLeft",
                attrs: {
                  to: { name: "BeatsCatalog", params: { pageIndex: 1 } },
                },
              },
              [
                _c("icon", {
                  attrs: { name: "arrow-left", width: "14", height: "14" },
                }),
                _vm._v("GO BACK"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _vm.isLoading || _vm.beats.length === 0
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [
              _c(
                "draggable",
                {
                  staticClass: "grid grid__col4 grid__gap--small",
                  attrs: { list: _vm.beats },
                  on: { end: _vm.onEnd },
                },
                _vm._l(_vm.beats, function (beat, index) {
                  return _c("div", { key: beat.id, staticClass: "box-drag" }, [
                    _vm._v(_vm._s(beat.name)),
                    _c("div", { staticClass: "indexNumber" }, [
                      _vm._v(_vm._s(index + 1)),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h3", [_vm._v("Beats ")]),
      _c("h1", [_vm._v("Reorder ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }